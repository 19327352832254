import { Box, Center, Checkbox, Container, HStack, Input, SlideFade, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Tag, TagCloseButton, TagLabel, Text, Textarea, VStack, Tooltip } from '@chakra-ui/react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import backgroundYellow from "../../assets/destaques/backgroundyellow.png";
import { GhostButton, YellowButton, IconGhostButton } from '../../components/button';
import LimitedCheckboxGroup from '../../components/checkbox';
import Header from '../../components/header';
import { Loading } from '../../components/loading';
import { LoadingSendVideo } from '../../components/loadingSendVideo';
import { DocTemplateModal } from '../../components/docTemplateModal';
import { TermsOfUse } from '../../components/termsOfUse';
import { ErrorModal } from '../../components/errorModal';
import { useAuth } from '../../hooks/auth';
import Api from '../../services/api';
import { colors } from '../../styles/colors';
import Confetti from 'react-confetti';
import { CongratsModal } from '../../components/congratsModal';
import { FaTrash, FaFile } from 'react-icons/fa';

const ProductUpload = () => {
  const { user } = useAuth();

  const myCustomIcon = new L.DivIcon({
    html: `<div style="background: transparent; border: none; font-size: 12px; color: #FFCC00;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24" height="24" fill="currentColor">
    <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 98.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/>
  </svg></div>`,
    iconSize: [40, 40],
    iconAnchor: [20, 20]
  });

  const allowedExtensions = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [windowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [modalCongrats, setModalCongrats] = useState(false);
  const [modalDocTemplate, setModalDocTemplate] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSendVideo, setLoadingSendVideo] = useState(false);
  const [uploadProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [docFile, setDocFile] = useState(null);
  const [willUseSameData, setWillUseSameData] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [cityData, setCityData] = useState({ city: '', state: '', region: '', lat: '', lng: '' });
  const [cityError, setCityError] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    tags: [],
    description: '',
    categories: [],
    peopleRecog: false,
    acceptTerms: false,
    camera: '',
    location: '',
    price: 0,
    gPSLat: '',
    gPSLong: '',
    docFile: '',
    errors: {
      title: '',
      tags: '',
      description: '',
      categories: '',
      acceptTerms: '',
      location: '',
      lat: '',
      long: '',
    }
  });
  const [allCategories, setAllCategories] = useState([]);
  const [resetCategorias, setResetCategorias] = useState(false);
  const [categories, setCategories] = useState([]);
  const [sliderValuePrice, setSliderValuePrice] = useState(10);
  const [minPrice, setMinPrice] = useState(10);
  const [maxPrice, setMaxPrice] = useState(50);
  const [position, setPosition] = useState(null);
  const [loadingThumb, setLoadingThumb] = useState(false);
  const [thumbStep1, setThumbStep1] = useState(null);
  const [modalError, setModalError] = useState(false);
  const [errorsModal, setErrorsModal] = useState([]);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (minPrice > 0 && formData.price === 0) {
      setSliderValuePrice(minPrice);
      setFormData({ ...formData, price: minPrice });
    }
  }, [minPrice]);

  useEffect(() => {
    if (willUseSameData) {
      // Se o usuário escolher usar os mesmos dados
      var formFromStorage = JSON.parse(localStorage.getItem('@formDataVideo'));
      if (formFromStorage != null) {
        const { videoFile, ...restFormData } = formFromStorage;
        setFormData({ ...restFormData, price: restFormData.price || minPrice });
        setSliderValuePrice(restFormData.price || minPrice);
        setVideoFile(null);
        setVideoUrl(null);
        setStep(0);
      }
    } else {
      // Se o usuário optar por um novo upload, resetar os dados
      const resetFormData = {
        title: '',
        tags: [],
        description: '',
        categories: [],
        peopleRecog: false,
        acceptTerms: false,
        camera: '',
        location: '',
        price: minPrice,
        gPSLat: '',
        gPSLong: '',
        docFile: '',
        errors: {
          title: '',
          tags: '',
          description: '',
          categories: '',
          acceptTerms: '',
          location: '',
          lat: '',
          long: '',
        }
      };

      setFormData(resetFormData); // Atualiza o formData com os valores resetados
      setSliderValuePrice(minPrice);
      setCityData({ city: '', state: '', region: '', lat: '', lng: '' }); // Resetando os dados de cidade
      setPosition(null); // Resetando a posição do mapa
      setVideoFile(null);
      setVideoUrl(null);
    }
  }, [resetKey]);

  useEffect(() => {
    if (sliderValuePrice > 0) {
      setFormData(prevFormData => ({
        ...prevFormData,
        price: sliderValuePrice
      }));
    }
  }, [sliderValuePrice]);

  useEffect(() => {
    if (docFile !== null && docFile !== '') {
      setFormData({ ...formData, docFile: docFile });
    } else {
      setFormData({ ...formData, docFile: '' });
    }
  }, [docFile]);

  useEffect(() => {
    if (formData) setFormData({ ...formData, categories: categories });
  }, [categories]);

  useEffect(() => {
    if (step == 5) {
      setLoadingThumb(true);
      setTimeout(() => {
        var url = captureThumbnail();
        setThumbStep1(url);
        setLoadingThumb(false);
      }, 250);
    }
  }, [sliderValue]);

  const captureThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageDataUrl = canvas.toDataURL('image/jpeg');
    return imageDataUrl;
  };

  const validateStep = () => {
    let isValid = true;
    let errors = { ...formData.errors };

    if (step === 1) {
      if (formData.title.length === 0) {
        errors.title = 'Você precisa inserir um título.';
        isValid = false;
      } else if (formData.title.length > 55) {
        errors.title = 'O título precisa ter menos de 55 caracteres.';
        isValid = false;
      } else {
        errors.title = '';
      }

      if (formData.tags.length === 0) {
        errors.tags = 'Você precisa informar ao menos uma tag.';
        isValid = false;
      } else {
        errors.tags = '';
      }

      if (formData.description.length === 0) {
        errors.description = 'Você precisa inserir uma descrição.';
        isValid = false;
      } else if (formData.description.length > 500) {
        errors.description = 'A descrição precisa ter menos de 500 caracteres.';
        isValid = false;
      } else {
        errors.description = '';
      }
    }

    if (step === 2) {
      if (formData.categories.length === 0) {
        errors.categories = 'Você precisa selecionar ao menos uma categoria.';
        isValid = false;
      } else {
        errors.categories = '';
      }
    }

    if (step === 3) {
      if (!formData.acceptTerms) {
        errors.acceptTerms = 'Você precisa aceitar os termos de uso.';
        isValid = false;
      } else {
        errors.acceptTerms = '';
      }

      if (formData.peopleRecog && !docFile) {
        errors.docFile = 'Você precisa fazer o upload do documento de direitos de imagem.';
        isValid = false;
      } else {
        errors.docFile = '';
      }
    }

    if (step === 4) {
      if (cityData.city.trim() === '') {
        errors.location = 'Você precisa informar a cidade.';
        isValid = false;
      } else {
        errors.location = '';
      }

      if (!cityData.lat || !cityData.lng) {
        errors.lat = 'Latitude e Longitude são obrigatórias.';
        isValid = false;
      } else {
        errors.lat = '';
        errors.long = '';
      }
    }

    setFormData({ ...formData, errors });
    return isValid;
  };

  const handleTimeUpdate = (e) => {
    e.stopPropagation();
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      setSliderValue(videoRef.current.currentTime);
    }
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setVideoUrl(url);
      setVideoFile(file);
      // Atualiza o título do vídeo com o nome do arquivo sem a extensão
      if (!willUseSameData) {
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
        setFormData((prev) => ({ ...prev, title: fileNameWithoutExtension }));
      }
      if (videoRef.current) {
        videoRef.current.load(); // Adiciona verificação para garantir que o elemento de vídeo seja recarregado
      }
      setStep(1);
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const updatedTags = formData.tags.filter(tag => tag !== tagToRemove);
    setFormData({ ...formData, tags: updatedTags });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value.trim();
      if (value && !formData.tags.includes(value) && formData.tags.length < 15) {
        const updatedTags = [...formData.tags, value];
        setFormData({ ...formData, tags: updatedTags });
        e.target.value = '';
      }
    }
  };

  const handleCloseModalError = () => {
    setModalError(false);
    setErrorsModal([]);
  };

  async function load() {
    const categories = await Api.get('/categories');
    const categoriesArray = Object.values(categories.data);
    setAllCategories(categoriesArray.map(x => ({ value: x.idCategories, label: x.description })));

    const response = await Api.get('/commissionvaluetype');
    const commissionValuesArray = Object.values(response.data);
    const priceRanges = commissionValuesArray.map(item => parseFloat(item.videoPriceRange));
    setMinPrice(Math.min(...priceRanges));
    setMaxPrice(Math.max(...priceRanges));
    setSliderValuePrice(Math.min(...priceRanges));
  }

  const fetchCityData = async (cityName) => {
    setLoading(true);
    try {
      const response = await Api.get(`/geocoder/cities?name=${cityName}`);
      if (response.data) {
        setCityData({
          city: response.data.city,
          state: response.data.state,
          region: response.data.region,
          lat: response.data.lat,
          lng: response.data.lng
        });
        setFormData({ ...formData, gPSLat: response.data.lat, gPSLong: response.data.lng, location: response.data.city });
        setPosition([response.data.lat, response.data.lng]);
        setCityError('');
      } else {
        setCityError('Município não encontrado.');
      }
    } catch (error) {
      setCityError('Erro ao buscar município.');
    } finally {
      setLoading(false);
    }
  };

  const handleCityChange = (e) => {
    const cityName = e.target.value;
    setCityData({ ...cityData, city: cityName });

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      if (cityName.trim().length > 0) {
        fetchCityData(cityName);
      } else {
        setCityData({ city: '', state: '', region: '', lat: '', lng: '' });
        setPosition(null);
      }
    }, 1000)); // 1 segundo de espera após o usuário parar de digitar
  };

  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setPosition(e.latlng);
        map.flyTo([lat, lng], map.getZoom());
      }
    });

    return position === null ? null : (
      <Marker position={position} icon={myCustomIcon} />
    );
  };

  const sendVideo = async () => {
    setLoadingSendVideo(true);

    const form = new FormData();

    form.append('idUser', user.id);
    form.append('title', formData.title);
    form.append('tags', JSON.stringify(formData.tags));
    form.append('descriptionScene', formData.description);
    form.append('categories', JSON.stringify(formData.categories));
    form.append('camera', formData.camera);
    form.append('peopleRecognizable', formData.peopleRecog);
    form.append('acceptTerms', formData.acceptTerms);
    form.append('price', formData.price === 0 ? sliderValuePrice : formData.price);
    form.append('location', formData.location);
    form.append('gPSLat', formData.gPSLat);
    form.append('gPSLong', formData.gPSLong);
    form.append('videoFile', videoFile);

    var thumbFile = captureThumbnail();
    form.append('thumbFile', thumbFile);
    form.append('thumbTime', currentTime);

    if (formData.peopleRecog && docFile) {
      form.append('docFile', docFile);
    }

    try {
      const response = await Api.post('/video', form, {
        headers: { 'Content-Type': 'multipart/form-data; charset=utf-8' }
      });

      console.log('Resposta:', response.data);
      setLoadingSendVideo(false);
      setModalCongrats(true);
    } catch (error) {
      console.error('Erro:', error);

      // Define os erros da resposta da API
      const errors = error.response?.data?.errors || [{ msg: 'Erro desconhecido ao enviar vídeo.' }];
      setErrorsModal(errors); // Armazena os erros no estado para exibir no modal
      setModalError(true); // Abre o modal de erro

      setLoadingSendVideo(false);
    }
  };


  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const handleDocFileChange = (event) => {
    const file = event.target.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    let errors = { ...formData.errors };

    if (file && allowedExtensions.includes(fileExtension)) {
      setDocFile(file);
      setFormData({ ...formData, docFile: file, errors: { ...errors, docFile: '' } });
    } else {
      setDocFile(null);
      setFormData({ ...formData, docFile: '', errors: { ...errors, docFile: 'Formato de arquivo não permitido.' } });
    }

    if (!formData.peopleRecog) {
      errors.docFile = '';
    }

    setFormData({ ...formData, errors });
  };

  const handleRemoveDocFile = () => {
    setDocFile(null);
    setFormData({ ...formData, docFile: '' });
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header dark />
        <div style={{ paddingBottom: 25, paddingTop: 25, position: 'relative', backgroundColor: step < 5 ? colors.black : "transparent" }}>
          {videoUrl && step >= 5 && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: "75%",
              background: 'linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 60%)',
              zIndex: 0,
              pointerEvents: 'none',
              opacity: 1
            }} />
          )}

          {step < 5 && <div style={{
            position: 'absolute',
            top: -100,
            left: 0,
            width: '100%',
            height: "100vh",
            backgroundImage: `url(${backgroundYellow})`,
            zIndex: 0,
            pointerEvents: 'none',
            backgroundSize: 'cover',
            opacity: 0.05
          }} />}

          <Container maxW="container.xl" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" height='full' py={5} zIndex={1} position={"relative"}>
            {step === 5 && <VStack align="flex-start" justifyContent="flex-start" w="full" mb={5}>
              <Text fontSize="3xl">Upload de conteúdo</Text>
            </VStack>}

            {loading && <Loading />}
            {loadingSendVideo && <LoadingSendVideo progress={uploadProgress} />}

            {!loading && !loadingSendVideo && <>
              {step === 0 && (
                <VStack align="flex-start" justifyContent="flex-start" h="full" backgroundColor={colors.grayBack}>
                  <SlideFade offsetY={20} in={step >= 0} w="100%">
                    <Center
                      p={"50px"}
                      borderRadius="lg"
                      borderWidth="1px"
                      borderColor={colors.primary}
                      borderStyle="dashed"
                    >
                      <VStack spacing={4}>
                        <input
                          type="file"
                          accept="video/*"
                          onChange={handleVideoUpload}
                          style={{ display: 'none' }}
                          id="hidden-file-input"
                        />
                        <YellowButton
                          onClick={() => document.getElementById('hidden-file-input').click()}
                          text="Clique aqui para selecionar o seu vídeo"
                        />
                        <Text fontSize="sm" color={colors.primary}>
                          Envie seu conteúdo
                        </Text>
                        <Text fontSize="sm" color={colors.primary}>
                          Arquivos até <strong>500mb</strong> ou <strong>60s</strong>.
                        </Text>
                      </VStack>
                    </Center>
                  </SlideFade>
                </VStack>
              )}

              <HStack justifyContent="center" alignItems="flex-start" spacing={10} w="full" py={5}>
                {step >= 1 && (
                  <VStack align="flex-start" justifyContent="flex-start" h="full" w="25%">
                    <SlideFade offsetY={20} in={step >= 1}>
                      <HStack justifyContent="flex-start" w="full" gap={4} mb={10}>
                        <Box display="flex" justifyContent="center" alignItems="center" borderWidth={2} borderColor={step === 1 ? colors.primary : colors.grayBack} w="25px" h="25px" borderRadius={25}>
                          <Text fontSize="sm" color={colors.white75}>
                            1
                          </Text>
                        </Box>
                        <Text fontSize="md" color={colors.white75}>
                          Identificação
                        </Text>
                        <Text fontSize="xs" backgroundColor={colors.grayBack} color={colors.white75} borderRadius={5} padding="4px 7.5px">
                          {formData?.tags?.length}/15 tags
                        </Text>
                      </HStack>

                      <Text fontSize="md" mt={4} mb={2} color={colors.white50}>
                        Título do vídeo
                      </Text>
                      <Input
                        disabled={step !== 1}
                        variant="unstyled"
                        placeholder='Digite aqui o título do vídeo...'
                        size="sm"
                        w="full"
                        color="white"
                        borderColor={colors.white50}
                        borderWidth={1}
                        borderRadius={8}
                        p={2}
                        _focus={{ borderColor: colors.primary }}
                        value={formData?.title}
                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                      />
                      {formData?.errors?.title && <Text fontSize="xs" mt={2} color="red">{formData.errors.title}</Text>}

                      <Text fontSize="md" mt={5} mb={5} color={colors.white50}>
                        Digite até 15 tags para seu vídeo
                      </Text>
                      <HStack spacing={2} my={3} wrap="wrap">
                        {formData?.tags.map((tag, index) => (
                          <Tag
                            key={index}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="yellow"
                            backgroundColor={colors.primary}
                            color={colors.black}
                            borderWidth={1}
                            borderColor={colors.whiteTransparent}
                          >
                            <TagLabel>{tag}</TagLabel>
                            {step === 1 && <TagCloseButton onClick={() => handleTagRemove(tag)} />}
                          </Tag>
                        ))}
                        <Input
                          mt={1}
                          disabled={step !== 1}
                          variant="unstyled"
                          placeholder="Digite e aperte enter para adicionar ..."
                          size="sm"
                          color="white"
                          borderColor={colors.white50}
                          borderWidth={1}
                          borderRadius={8}
                          p={2}
                          _focus={{ borderColor: colors.primary }}
                          onKeyDown={handleKeyDown}
                        />
                      </HStack>
                      {formData?.errors?.tags && <Text fontSize="xs" mt={2} color="red">{formData.errors.tags}</Text>}

                      <Text fontSize="md" mt={4} mb={2} color={colors.white50}>
                        E uma descrição curta
                      </Text>
                      <Textarea
                        disabled={step !== 1}
                        variant="unstyled"
                        placeholder='Digite aqui sua descrição...'
                        size="sm"
                        w="full"
                        color="white"
                        borderColor={colors.white50}
                        borderWidth={1}
                        borderRadius={8}
                        p={2}
                        _focus={{ borderColor: colors.primary }}
                        value={formData?.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      />
                      {formData?.errors?.description && <Text fontSize="xs" mt={2} color="red">{formData.errors.description}</Text>}

                      {videoUrl && step == 5 && (
                        <Box w="100%">
                          <Text fontSize="md" mt={4} mb={2} color={colors.white50}>
                            Thumbnail
                          </Text>
                          {!thumbStep1 && <Text fontSize="md" mt={4} mb={2} color={colors.white50}>
                            Arraste a barra do vídeo para gerar uma thumb
                          </Text>}
                          {loadingThumb &&
                            <Box height="150px" width="100%" display="flex" alignItems="center" justifyContent="center">
                              <Loading />
                            </Box>}
                          <canvas
                            ref={canvasRef}
                            style={{
                              height: 150,
                              borderRadius: 10,
                              borderWidth: 2,
                              borderColor: colors.primary,
                              display: loadingThumb || !thumbStep1 ? "none" : "block",
                            }}
                          />
                        </Box>
                      )}

                    </SlideFade>
                  </VStack>
                )}

                {step >= 2 && (
                  <VStack align="flex-start" justifyContent="flex-start" h="full" w="25%">
                    <SlideFade offsetY={20} in={step >= 2}>
                      <HStack justifyContent="flex-start" w="full" gap={4} mb={10}>
                        <Box display="flex" justifyContent="center" alignItems="center" borderWidth={2} borderColor={step === 2 ? colors.primary : colors.grayBack} w="25px" h="25px" borderRadius={25}>
                          <Text fontSize="sm" color={colors.white75}>
                            2
                          </Text>
                        </Box>
                        <Text fontSize="md" color={colors.white75}>
                          Categorias
                        </Text>
                        <Text fontSize="xs" backgroundColor={colors.grayBack} color={colors.white75} borderRadius={5} padding="4px 7.5px">
                          {categories.length}/5 categorias
                        </Text>
                      </HStack>
                      <Text fontSize="md" mt={2} mb={5} color={colors.white50}>
                        Escolha até 5 categorias para seu vídeo
                      </Text>
                      <LimitedCheckboxGroup
                        disabled={step !== 2}
                        value={categories}
                        setValues={setCategories}
                        options={allCategories}
                        maxSelections={5}
                        resetCategorias={resetCategorias} // Passa o resetCategorias para o componente
                        setResetCategorias={setResetCategorias}
                      />
                      {formData?.errors?.categories && <Text fontSize="xs" mt={2} color="red">{formData.errors.categories}</Text>}
                    </SlideFade>
                  </VStack>
                )}

                {step >= 3 && (
                  <VStack align="flex-start" justifyContent="flex-start" h="full" w="25%">
                    <SlideFade offsetY={20} in={step >= 3}>
                      <HStack justifyContent="flex-start" w="full" gap={4} mb={10}>
                        <Box display="flex" justifyContent="center" alignItems="center" borderWidth={2} borderColor={step === 3 ? colors.primary : colors.grayBack} w="25px" h="25px" borderRadius={25}>
                          <Text fontSize="sm" color={colors.white75}>
                            3
                          </Text>
                        </Box>
                        <Text fontSize="md" color={colors.white75}>
                          Ficha Técnica e Valores
                        </Text>
                      </HStack>
                      <Text fontSize="md" mt={2} mb={5} color={colors.white50}>
                        Nos dê mais detalhes do seu vídeo
                      </Text>

                      <Text fontSize="md" mt={2} mb={2} color={colors.white50}>
                        Com qual câmera foi filmado?
                      </Text>

                      <Input
                        disabled={step !== 3}
                        variant="unstyled"
                        placeholder='Digite aqui sua câmera...'
                        size="sm"
                        w="full"
                        color="white"
                        borderColor={colors.white50}
                        borderWidth={1}
                        borderRadius={8}
                        p={2}
                        _focus={{ borderColor: colors.primary }}
                        value={formData?.camera}
                        onChange={(e) => setFormData({ ...formData, camera: e.target.value })}
                      />

                      <VStack justifyContent="flex-start" alignItems="flex-start" mt={5} mb={5}>
                        <Checkbox
                          colorScheme='yellow'
                          disabled={step !== 3}
                          isChecked={formData.peopleRecog} // Bind the checked state
                          onChange={(e) => setFormData({ ...formData, peopleRecog: e.target.checked })}
                        >
                          <Text fontSize="sm" color={colors.white75}>Existem pessoas reconhecíveis no vídeo?</Text>
                          <Text fontSize="xs" color={colors.white50}>Se sim, você precisará da licença de imagem pessoal</Text>
                        </Checkbox>

                        {formData?.peopleRecog && (
                          <VStack spacing={4} mb={5} justifyContent="flex-start" alignItems="flex-start">
                            <HStack w="full" spacing={4} alignItems="center">
                              <Box w="80%" display="flex" justifyContent="center">
                                <YellowButton
                                  size="xs"
                                  mt={2}
                                  onClick={() => {
                                    if (step === 3) {
                                      document.getElementById('hidden-file-input-doc').click();
                                    }
                                  }}
                                  text="Selecione aqui o documento"
                                  isDisabled={step !== 3}
                                />
                              </Box>
                              <Box w="20%" display="flex" justifyContent="left">
                                <Tooltip
                                  hasArrow
                                  arrowSize={15}
                                  label="Baixar modelos de documento"
                                  aria-label="Baixar modelos de documento"
                                  placement="top"
                                  closeOnClick={false}
                                  bg={colors.background}
                                  color={colors.white}
                                  shouldWrapChildren
                                >
                                  <IconGhostButton
                                    onClick={() => {
                                      if (step === 3) {
                                        setModalDocTemplate(true);
                                      }
                                    }}
                                    size="xs"
                                    mt={2}
                                    icon={<FaFile color={colors.white75} />}
                                    borderWidth={1}
                                    borderColor={colors.white75}
                                    isDisabled={step !== 3}
                                  />
                                </Tooltip>
                              </Box>
                            </HStack>
                            {docFile && (
                              <HStack w="full" justifyContent="space-between">
                                <Text fontSize="xs">Arquivo selecionado: {docFile.name}</Text>
                                <IconGhostButton
                                  onClick={() => {
                                    if (step === 3) {
                                      handleRemoveDocFile();
                                    }
                                  }}
                                  size="xs"
                                  icon={<FaTrash color={colors.red} />}
                                  borderWidth={1}
                                  borderColor={colors.red}
                                  isDisabled={step !== 3}
                                />
                              </HStack>
                            )}
                            <input
                              type="file"
                              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                              onChange={handleDocFileChange}
                              style={{ display: 'none' }}
                              id="hidden-file-input-doc"
                            />
                          </VStack>
                        )}
                        {formData?.errors?.docFile && <Text fontSize="xs" mt={2} color="red">{formData.errors.docFile}</Text>}
                        <Checkbox
                          colorScheme='yellow'
                          disabled={step !== 3}
                          isChecked={formData.acceptTerms} // Bind the checked state
                          onChange={(e) => setFormData({ ...formData, acceptTerms: e.target.checked })}
                        >
                          <Text fontSize="sm" color={colors.white75}>Aceito os termos de uso e publicação do vídeo</Text>
                          <Text fontSize="xs" color={colors.white50}>
                            Confira nossos termos de uso e licença padrão <strong onClick={() => setTermsOfUse(true)} style={{ cursor: 'pointer' }}>aqui</strong>
                          </Text>
                        </Checkbox>
                        {formData?.errors?.acceptTerms && <Text fontSize="xs" mt={2} color="red">{formData.errors.acceptTerms}</Text>}
                      </VStack>

                      <Text fontSize="md" mt={5} mb={5} color={colors.white50}>
                        Qual valor você espera receber por seu vídeo?
                      </Text>

                      <HStack>
                        <Text w="110px" mr={1}>{formatCurrency(minPrice)}</Text>
                        <Slider
                          colorScheme='yellow'
                          min={minPrice}
                          max={maxPrice}
                          step={10}
                          value={sliderValuePrice}
                          onChange={(value) => {
                            if (value !== sliderValuePrice) {
                              setSliderValuePrice(value);
                              setFormData({ ...formData, price: value });
                            }
                          }}
                          isDisabled={step !== 3}
                        >
                          <SliderTrack>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb>
                            <SliderMark
                              value={sliderValuePrice}
                              mt={12}
                              ml="-12"
                              w="24"
                              textAlign="center"
                            >
                              {formatCurrency(sliderValuePrice)}
                            </SliderMark>
                          </SliderThumb>
                        </Slider>
                        <Text w="110px" ml={3}>{formatCurrency(maxPrice)}</Text>
                      </HStack>
                    </SlideFade>
                  </VStack>
                )}

                {step >= 4 && (
                  <VStack align="flex-start" justifyContent="flex-start" h="full" w="25%">
                    <SlideFade offsetY={20} in={step >= 4} style={{ width: "100%" }}>
                      <HStack justifyContent="flex-start" w="full" gap={4} mb={10}>
                        <Box display="flex" justifyContent="center" alignItems="center" borderWidth={2} borderColor={step === 4 ? colors.primary : colors.grayBack} w="25px" h="25px" borderRadius={25}>
                          <Text fontSize="sm" color={colors.white75}>
                            4
                          </Text>
                        </Box>
                        <Text fontSize="md" color={colors.white75}>
                          Produção e Localização
                        </Text>
                      </HStack>

                      <Text fontSize="md" mt={4} mb={2} color={colors.white50}>
                        Cidade
                      </Text>
                      <Input
                        disabled={step !== 4}
                        variant="unstyled"
                        placeholder='Digite aqui a cidade...'
                        size="sm"
                        w="full"
                        color="white"
                        borderColor={colors.white50}
                        borderWidth={1}
                        borderRadius={8}
                        p={2}
                        _focus={{ borderColor: colors.primary }}
                        value={cityData.city}
                        onChange={handleCityChange}
                      />
                      {cityError && <Text fontSize="xs" mt={2} color="red">{cityError}</Text>}

                      {position && (
                        <>
                          <Text fontSize="md" mt={4} mb={2} color={colors.white50}>
                            Selecione no mapa o local de gravação do vídeo (o mapa pode ser arrastado e movido).
                          </Text>
                          <MapContainer center={position} zoom={10} style={{ height: '175px', width: '100%', borderRadius: 10 }}>
                            <TileLayer url={'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'} attribution="" />
                            <LocationMarker />
                          </MapContainer>
                        </>
                      )}

                      {formData?.errors?.lat && <Text fontSize="xs" mt={2} color="red">{formData.errors.lat}</Text>}
                      {formData?.errors?.long && <Text fontSize="xs" mt={2} color="red">{formData.errors.long}</Text>}
                    </SlideFade>
                  </VStack>
                )}
              </HStack>

              {step >= 5 && videoUrl && (
                <>
                  <VStack justifyContent="center" w="full" gap={4} mt={10}>
                    <Box display="flex" justifyContent="center" alignItems="center" borderWidth={2} borderColor={step === 5 ? colors.primary : colors.grayBack} w="25px" h="25px" borderRadius={25}>
                      <Text fontSize="sm" color={colors.white75}>
                        5
                      </Text>
                    </Box>
                    <Text fontSize="md" color={colors.white75}>
                      Para finalizar, escolha a Thumb do vídeo
                    </Text>
                  </VStack>

                  <Slider
                    mt={10}
                    min={0}
                    max={videoRef?.current?.duration || 0}
                    colorScheme='transparent'
                    value={sliderValue}
                    onChange={(value) => {
                      if (videoRef.current) {
                        videoRef.current.currentTime = value;
                        setSliderValue(value);
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <SliderTrack height={10} colorScheme={"transparent"} borderWidth={2} borderRadius={5}>
                      <SliderFilledTrack transition="all 0.25s ease-in-out" />
                    </SliderTrack>
                    <SliderThumb w={2} h={12} />
                  </Slider>
                </>
              )}

              {step >= 1 && (
                <Box mt={10}>
                  {step > 1 && <GhostButton text="Voltar" onClick={() => setStep(step - 1)} mr={5} />}
                  <YellowButton text={step === 5 ? "Publicar Vídeo" : "Continuar"} onClick={() => {
                    if (validateStep()) {
                      if (step === 5) {
                        sendVideo();
                      } else {
                        setStep(step + 1);
                      }
                    }
                  }} />
                </Box>
              )}

              {
                modalCongrats && <Confetti width={windowSize.width} height={windowSize.height} />
              }

              <CongratsModal isOpen={modalCongrats} onClose={() => setModalCongrats(false)} setStep={setStep} formData={formData} setFormData={setFormData} willUseSameData={willUseSameData} setWillUseSameData={setWillUseSameData} setResetKey={setResetKey} setResetCategorias={setResetCategorias} />
              <DocTemplateModal isOpen={modalDocTemplate} onClose={() => setModalDocTemplate(false)} />
              <TermsOfUse isOpen={termsOfUse} onClose={() => setTermsOfUse(false)} />
              <ErrorModal isOpen={modalError} onClose={handleCloseModalError} errors={errorsModal} />

            </>}
          </Container>
        </div>

        {videoUrl && <video
          ref={videoRef}
          width="100%"
          muted
          onTimeUpdate={handleTimeUpdate}
          style={{
            opacity: step === 5 ? 1 : 0,
            position: 'absolute',
            minHeight: '100vh',
            width: '100%',
            objectFit: 'cover',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: -1,
            filter: 'brightness(90%)'
          }}
        >
          {videoUrl && <source src={videoUrl} type="video/mp4" />}
          Seu navegador não suporta vídeo HTML5.
        </video>
        }

      </div>
    </>
  );
};

export default ProductUpload;
