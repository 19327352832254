import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Avatar,
    Box,
    Flex,
    Grid,
    HStack,
    Image,
    ScaleFade,
    Text,
    VStack,
    Tooltip,
    Spinner
} from '@chakra-ui/react';
import {
    FaPlay,
    FaTrash,
    FaEye,
    FaExclamationTriangle,
    FaDownload,
    FaFileAlt
} from 'react-icons/fa';
import fastBuyIcon from '../assets/icons/fast-buy.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';
import { colors } from '../styles/colors';
import { IconGhostButton, YellowButton } from './button';
import { FastBuy } from './fastBuy';
import { Loading } from './loading';
import { ConfirmationModal } from './confirmationModal';
import { VideoModal } from './videoModal';
import { ErrorModal } from './errorModal';
import { LicenseModal } from './licenseModal';

const formatCurrency = (price) => {
    return parseFloat(price).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
};

export const Videos = ({
    videos,
    setVideos,
    download = false,
    isOwnerView = false,
    openModal = false
}) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fastBuy, setFastBuy] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isPlaying, setIsPlaying] = useState({});
    const videoRefs = useRef([]);
    const [abortControllers, setAbortControllers] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenVideo, setModalOpenVideo] = useState(false);
    const [licenseModalOpen, setLicenseModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errors, setErrors] = useState([]);

    // Novo estado para indicar se o vídeo ainda está carregando
    // na hora do mouse hover
    const [videoLoading, setVideoLoading] = useState({});

    useEffect(() => {
        videoRefs.current = videos.map(() => React.createRef());
        setHoveredIndex(null);
        setIsPlaying({});
        setAbortControllers({});
    }, [videos]);

    // Fetch do vídeo com AbortController + timeout de 2s
    const fetchVideoWithTimeout = async (url, timeoutMs, controller) => {
        const timeoutId = setTimeout(() => {
            controller.abort();
        }, timeoutMs);
        const response = await fetch(url, { signal: controller.signal });
        clearTimeout(timeoutId);
        return response.blob();
    };

    // Tentar tocar o vídeo: se não tiver "src", faz fetch + AbortController
    const tryPlayVideo = (videoObj, index, attempts = 3) => {
        const videoElement = videoRefs.current[index]?.current;
        if (!videoElement) return;

        const currentSrc = videoElement.src;
        if (currentSrc && currentSrc.startsWith('blob:')) {
            // Já tem src (blob) definido: apenas tenta dar play
            const playPromise = videoElement.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        setIsPlaying((prev) => ({ ...prev, [index]: true }));
                    })
                    .catch(() => {
                        // Se não conseguiu dar play, tenta novamente até attempts esgotarem
                        if (attempts > 1) {
                            setTimeout(() => tryPlayVideo(videoObj, index, attempts - 1), 500);
                        }
                    });
            }
            return;
        }

        // Não tem src ainda: buscar do blob + abort em 2s
        const controller = new AbortController();
        setAbortControllers((prev) => ({ ...prev, [index]: controller }));

        // Seta loading de vídeo para true antes de fazer o fetch
        setVideoLoading((prev) => ({ ...prev, [index]: true }));

        fetchVideoWithTimeout(videoObj.videoUrl, 2000, controller)
            .then(blob => {
                if (controller.signal.aborted) return; // Se abortou, sai
                videoElement.src = URL.createObjectURL(blob);
                videoElement.load();
                videoElement.play().then(() => {
                    setIsPlaying((prev) => ({ ...prev, [index]: true }));
                }).catch(() => {
                    if (attempts > 1) {
                        setTimeout(() => tryPlayVideo(videoObj, index, attempts - 1), 500);
                    }
                });
            })
            .catch(error => {
                console.error(`Video load/play failed (attempt ${3 - attempts + 1}):`, error);
            })
            .finally(() => {
                // Assim que terminar (com erro ou sucesso), tira o loading
                setVideoLoading((prev) => ({ ...prev, [index]: false }));
            });
    };

    const handleMouseEnter = useCallback((index) => {
        setHoveredIndex(index);
        const videoObj = videos[index];
        if (!videoObj || isPlaying[index]) return;
        tryPlayVideo(videoObj, index);
    }, [videos, isPlaying]);

    const handleMouseLeave = useCallback((index) => {
        const videoElement = videoRefs.current[index]?.current;
        if (videoElement && isPlaying[index]) {
            videoElement.pause();
            videoElement.currentTime = 0;
            setIsPlaying((prev) => ({ ...prev, [index]: false }));
        }
        // Ao sair do hover, aborta se ainda estiver carregando
        const controller = abortControllers[index];
        if (controller && !controller.signal.aborted) {
            controller.abort();
        }
        setHoveredIndex(null);
    }, [isPlaying, abortControllers]);

    const handleTimeUpdate = useCallback((index) => {
        const videoElement = videoRefs.current[index]?.current;
        if (videoElement) {
            const isVideoPlaying = !videoElement.paused;
            setIsPlaying((prev) => ({ ...prev, [index]: isVideoPlaying }));
        }
    }, []);

    const handleFavorite = (video, event) => {
        event.stopPropagation();
        if (video.isFavorite === 0) {
            Api.post('/favorites', { idVideo: video.id })
                .then(() => {
                    setVideos(
                        videos.map(v => v.id === video.id ? { ...v, isFavorite: 1 } : v)
                    );
                })
                .catch(error => {
                    console.error("Failed to favorite video:", error);
                });
        } else {
            Api.delete('/favorites/video/' + video.id)
                .then(() => {
                    setVideos(
                        videos.map(v => v.id === video.id ? { ...v, isFavorite: 0 } : v)
                    );
                })
                .catch(error => {
                    console.error("Failed to favorite video:", error);
                });
        }
    };

    const handleToggleStatus = (video, event) => {
        event.stopPropagation();
        setSelectedVideo(video);
        setModalOpen(true);
    };

    const toggleVideoStatus = async () => {
        setLoading(true);
        try {
            if (selectedVideo.isOnline) {
                await Api.delete(`/video/${selectedVideo.id}`);
            } else {
                await Api.put(`/video/restore/${selectedVideo.id}`);
            }
            setVideos(
                videos.map(v =>
                    v.id === selectedVideo.id
                        ? { ...v, isOnline: !v.isOnline }
                        : v
                )
            );
        } catch (error) {
            console.error("Erro ao atualizar o status do vídeo: ", error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    async function downloadVideo(video) {
        setLoading(true);
        try {
            const result = await Api.get("/video/download/" + video.id);
            const url = result.data.url;
            const response = await fetch(url);
            const blob = await response.blob();

            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = video.id + '.mp4';
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(objectUrl);
            document.body.removeChild(a);

            setLoading(false);
        } catch (error) {
            console.error("Erro ao baixar o vídeo: ", error);
            const { response } = error;
            let errorMsg = 'Ocorreu um erro ao baixar o vídeo.';
            if (response) {
                if (response.status === 404) {
                    errorMsg = 'Baseado nas informações de vídeo e de compra, não foi encontrada uma compra válida.';
                } else if (response.status === 429) {
                    errorMsg = 'Limite de requisições de download atingido. Tente novamente após 45 minutos.';
                } else if (response.status === 500) {
                    errorMsg = 'Ocorreu um erro ao gerar o link para download.';
                }
            }
            setErrors([{ msg: errorMsg }]);
            setErrorModalOpen(true);
            setLoading(false);
        }
    }

    const handleVideoClick = (e, video) => {
        if (
            e.target.closest('button') ||
            e.target.tagName === 'svg' ||
            e.target.tagName === 'path'
        ) {
            return;
        }
        if (openModal) {
            setSelectedVideo(video);
            setModalOpenVideo(true);
        } else {
            navigate("/produto/" + video.id);
        }
    };

    return (
        <>
            <Grid
                templateColumns={{
                    base: 'repeat(1, 1fr)', // 1 coluna no mobile
                    md: 'repeat(2, 1fr)',   // 2 colunas no tablet
                    lg: 'repeat(3, 1fr)',   // 3 colunas no desktop
                }}
                gap={2}
                position="relative"
            >
                {/* Modal de compra rápida (FastBuy) */}
                <FastBuy
                    video={fastBuy}
                    isOpen={fastBuy != null}
                    onClose={() => setFastBuy(null)}
                />

                {/* Se não há vídeos, exibe um aviso */}
                {videos.length === 0 && (
                    <HStack w={"full"} justifyContent="space-between">
                        <Text fontSize="md" px={3} py={1} color={colors.white75}>
                            Sem vídeos a serem exibidos aqui.
                        </Text>
                    </HStack>
                )}

                {/* Lista de vídeos */}
                {videos.map((video, index) => (
                    <ScaleFade in={true} key={video.id}>
                        <Box
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                            height="250px"
                            position="relative"
                            overflow="hidden"
                            onClick={(e) => handleVideoClick(e, video)}
                        >
                            {/* Thumbnail com cover e posição centralizada */}
                            <Box
                                backgroundImage={`url(${video.thumbnailUrl})`}
                                backgroundRepeat="no-repeat"
                                backgroundPosition="center center"
                                backgroundSize="cover"
                                height="100%"
                                width="100%"
                                position="absolute"
                                top={0}
                                left={0}
                                zIndex={0}
                            />

                            {/* Overlay escurecedor */}
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                zIndex={2}
                                backgroundColor="rgba(0, 0, 0, 0.3)"
                            />

                            {/* Vídeo, também sem bordas pretas */}
                            <video
                                ref={videoRefs.current[index]}
                                crossOrigin="anonymous"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                    display: hoveredIndex === index ? 'block' : 'none',
                                    opacity: hoveredIndex === index ? 1 : 0,
                                    transition: "opacity 1s",
                                }}
                                loop
                                muted
                                onTimeUpdate={() => handleTimeUpdate(index)}
                            />

                            {/* SPINNER de loading quando está em hover e o fetch ainda não terminou */}
                            {hoveredIndex === index && videoLoading[index] && (
                                <Flex
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    right={0}
                                    bottom={0}
                                    zIndex={4}
                                    bg="rgba(0, 0, 0, 0.5)"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Spinner size="xl" color="white" />
                                </Flex>
                            )}

                            <VStack
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                justifyContent="space-between"
                                alignItems="flex-start"
                                spacing={4}
                                p={5}
                                zIndex={3}
                                opacity={hoveredIndex === index ? 1 : 0}
                                transition="opacity 1s"
                            >
                                <HStack w={"full"} justifyContent="space-between">
                                    <Text></Text>
                                    <Text
                                        fontSize="sm"
                                        px={3}
                                        py={1}
                                        color={colors.white75}
                                    >
                                        {formatCurrency(video.price)}
                                    </Text>
                                </HStack>
                                <HStack w={"full"} justifyContent="space-between">
                                    <Flex>
                                        <Avatar
                                            name={video.fullName}
                                            size="sm"
                                            backgroundColor={colors.primary}
                                            color={colors.black}
                                        />
                                        <Text fontSize="xs" ml={2}>
                                            {video.state}
                                            <Text fontWeight="bold">
                                                {video.fullName}
                                            </Text>
                                        </Text>
                                    </Flex>

                                    {/* Botões (Favorito, FastBuy, Play) se não for modo download e não for isOwnerView */}
                                    {!download && !isOwnerView && (
                                        <Flex justifyContent="space-between" gap={2}>
                                            {user && (
                                                <IconGhostButton
                                                    className="favorite-button"
                                                    color={colors.white75}
                                                    onClick={(e) => handleFavorite(video, e)}
                                                    icon={
                                                        <Image
                                                            src={require(
                                                                video?.isFavorite === 1
                                                                    ? "../assets/icons/heartpink.png"
                                                                    : "../assets/icons/heart.png"
                                                            )}
                                                            w="20px"
                                                        />
                                                    }
                                                />
                                            )}
                                            {user && (
                                                <IconGhostButton
                                                    color={colors.white75}
                                                    onClick={() => setFastBuy(video)}
                                                    icon={
                                                        <Image src={fastBuyIcon} w="20px" />
                                                    }
                                                    borderWidth={1}
                                                    borderColor={colors.white75}
                                                />
                                            )}
                                            <IconGhostButton
                                                color={colors.white75}
                                                onClick={() =>
                                                    navigate("/produto/" + video.id)
                                                }
                                                icon={<FaPlay />}
                                                borderWidth={1}
                                                borderColor={colors.white75}
                                            />
                                        </Flex>
                                    )}

                                    {/* Modo download (tem que estar logado) */}
                                    {download && user && (
                                        <Flex justifyContent="space-between" gap={2}>
                                            <Tooltip
                                                hasArrow
                                                arrowSize={15}
                                                label="Baixar licença do vídeo"
                                                aria-label="Baixar licença do vídeo"
                                                placement="top"
                                                closeOnClick={false}
                                                bg={colors.background}
                                                color={colors.white}
                                                shouldWrapChildren
                                            >
                                                <IconGhostButton
                                                    color={colors.white75}
                                                    onClick={() => {
                                                        setSelectedVideo(video);
                                                        setLicenseModalOpen(true);
                                                    }}
                                                    icon={<FaFileAlt />}
                                                    borderWidth={1}
                                                    borderColor={colors.white75}
                                                />
                                            </Tooltip>
                                            {loading ? (
                                                <Loading p={0} />
                                            ) : (
                                                <YellowButton
                                                    text="Download"
                                                    onClick={() => downloadVideo(video)}
                                                    icon={<FaDownload />}
                                                />
                                            )}
                                        </Flex>
                                    )}

                                    {/* Se for o proprietário e estiver na "OwnerView" */}
                                    {isOwnerView && (
                                        <Flex justifyContent="space-between" gap={2}>
                                            {video.IsApproval === false && (
                                                <Tooltip
                                                    hasArrow
                                                    arrowSize={15}
                                                    label={
                                                        video.RejectReason
                                                            ? video.RejectReason
                                                            : "O vídeo está em aprovação pelo moderador"
                                                    }
                                                    aria-label={
                                                        video.RejectReason
                                                            ? "Motivo da Rejeição"
                                                            : "O vídeo está em aprovação pelo moderador"
                                                    }
                                                    placement="top"
                                                    closeOnClick={false}
                                                    bg={colors.background}
                                                    color={colors.white}
                                                    shouldWrapChildren
                                                >
                                                    <IconGhostButton
                                                        color={colors.white75}
                                                        icon={<FaExclamationTriangle />}
                                                        borderWidth={1}
                                                        borderColor={colors.white75}
                                                    />
                                                </Tooltip>
                                            )}
                                            <IconGhostButton
                                                color={colors.white75}
                                                onClick={(e) => handleToggleStatus(video, e)}
                                                icon={video.isOnline ? <FaTrash /> : <FaEye />}
                                                borderWidth={1}
                                                borderColor={colors.white75}
                                            />
                                        </Flex>
                                    )}
                                </HStack>
                            </VStack>
                        </Box>
                    </ScaleFade>
                ))}
            </Grid>

            {/* FastBuy Modal (repetido pois estava no topo, mantendo compatibilidade) */}
            <FastBuy
                video={fastBuy}
                isOpen={fastBuy != null}
                onClose={() => setFastBuy(null)}
            />

            {/* Confirmation Modal (excluir ou restaurar vídeo) */}
            <ConfirmationModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={toggleVideoStatus}
                video={selectedVideo}
            />

            {/* Modal de preview (se openModal = true) */}
            {selectedVideo && (
                <VideoModal
                    isOpen={modalOpenVideo}
                    onClose={() => setModalOpenVideo(false)}
                    video={selectedVideo}
                />
            )}

            {/* Modal de erros */}
            <ErrorModal
                isOpen={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                errors={errors}
            />

            {/* Modal de licença (para download) */}
            {selectedVideo && (
                <LicenseModal
                    isOpen={licenseModalOpen}
                    onClose={() => setLicenseModalOpen(false)}
                    videoId={selectedVideo.id}
                />
            )}
        </>
    );
};