import { useBreakpointValue, Avatar, Box, Button, IconButton, Center, Container, Flex, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Popover, PopoverBody, PopoverContent, PopoverTrigger, SlideFade, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { FaList, FaMoneyBillWave } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import backgroundYellow from "../assets/destaques/backgroundyellow.png";
import { Cart } from '../components/cart';
import { HowItWorks } from '../components/howItWorks';
import { HowItWorksVideo } from '../components/howItWorksVideo';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';
import { colors } from '../styles/colors';
import { BlackButton, GhostButton, IconGhostButton, YellowButton } from './button';
import { CustomFormInput, CustomInput } from './input';
import { Loading } from './loading';
import { Profile } from './profile';
import { NoticesModal } from '../components/noticiesModal';

const Header = ({ dark = false, token = null, ...rest }) => {
    const navigate = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { user, signIn, signOut, headerExpanded, faturamentoOpen, faturamentoOpenModal } = useAuth();

    const [hasAdministratorRole, setHasAdministratorRole] = useState(false);

    useEffect(() => {
        if (user && user.token) {
            const decoded = jwtDecode(user.token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                signOut();
            }

            setHasAdministratorRole(user.roles && Object.values(user.roles).some(role => role.description === "administrator"));
        }
    }, [user, signOut]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState(false);

    const [modalLogin, setModalLogin] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const [modalReset, setModalReset] = useState(false);
    const [modalEsqueci, setModalEsqueci] = useState(false);
    const [modalCadastro, setModalCadastro] = useState(false);
    const [modalProfile, setModalProfile] = useState(false);
    const [modalProfileOption, setModalProfileOption] = useState(0);
    const [howItWorks, setHowItWorks] = useState(false);
    const [howItWorksVideo, setHowItWorksVideo] = useState(false);

    const [showMobileUploadNotice, setShowMobileUploadNotice] = useState(false);

    useEffect(() => {
        if (token != null) {
            setModalReset(true);
        }
    }, [token]);

    useEffect(() => {
        if (headerExpanded != null && headerExpanded !== "null") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            toggleExpansion();
        }
    }, [headerExpanded]);

    useEffect(() => {
        if (faturamentoOpen) {
            faturamentoOpenModal();
            setModalProfileOption(8);
            setModalProfile(true);
        }
    }, [faturamentoOpen]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [values, setValues] = useState({
        name: "",
        phone: "",
        emailCadastro: "",
        passwordCadastro: "",
        identity: "",
        acceptTerms: false
    });

    const [valuesEsqueci, setValuesEsqueci] = useState({ emailCadastro: "" });
    const [esqueciSuccess, setEsqueciSuccess] = useState(false);

    const [valuesReset, setValuesReset] = useState({ emailCadastro: "", passwordCadastro: "" });
    const [resetSuccess, setResetSuccess] = useState(false);

    const [errors, setErrors] = useState([]);

    async function login() {
        setLoading(true);
        let newErrors = [];
        try {
            if (!isValidEmail(email)) {
                newErrors.push({ path: "login", msg: "E-mail inválido" });
                setErrors(newErrors);
                setLoading(false);
                return;
            }
            const response = await signIn({
                email: email,
                password: password,
            });
            if (response && response.error) {
                setLoading(false);
                setErrors([{ path: "login", msg: response.error }]);
                return;
            }
            setModalLogin(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Erro ao fazer login', error);
        }
    }

    const [textColor, setTextColor] = useState(colors.white75);

    const toggleExpansion = () => {
        setIsExpanded(prevState => !prevState);
        setTextColor(prevColor => (prevColor === colors.white75 ? colors.black : colors.white75));
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    function isValidCPF(cpf) {
        let sum = 0;
        let remainder;

        if (cpf === '00000000000') return false;

        for (let i = 1; i <= 9; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.substring(9, 10))) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        return remainder === parseInt(cpf.substring(10, 11));
    };

    function isValidCNPJ(cnpj) {
        let sum = 0;
        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0, size);
        let digits = cnpj.substring(size);
        let pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
        }

        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result !== parseInt(digits.charAt(0))) return false;

        size = size + 1;
        numbers = cnpj.substring(0, size);
        sum = 0;
        pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        return result === parseInt(digits.charAt(1));
    };

    const isValidPhone = (phone) => {
        return /^\d{2}\d{8,9}$/.test(phone);
    };

    const handleCadastro = async () => {
        setLoading(true);
        let newErrors = [];
        setErrors([]);

        try {
            if (values.name.trim() === "") {
                newErrors.push({ path: "name", msg: "Nome inválido" });
            }

            if (values.phone.trim() === "" || !isValidPhone(values.phone)) {
                newErrors.push({
                    path: "phone",
                    msg: "Telefone inválido. Insira o DDD(55, 51...) seguido de seu número completo, sem espaços ou hífen."
                });
            }

            if (!isValidEmail(values.emailCadastro)) {
                newErrors.push({ path: "emailCadastro", msg: "E-mail inválido" });
            }

            if (values.passwordCadastro.length < 8) {
                newErrors.push({
                    path: "passwordCadastro",
                    msg: "A senha deve ter pelo menos 8 caracteres"
                });
            }

            // Verificação do checkbox
            if (!values.acceptTerms) {
                newErrors.push({
                    path: "acceptTerms",
                    msg: "É necessário aceitar os Termos de Uso para prosseguir."
                });
            }

            const rawIdentity = values.identity.replace(/\D/g, "").trim();

            if (rawIdentity.length === 11) {
                if (!isValidCPF(rawIdentity)) {
                    newErrors.push({
                        path: "identity",
                        msg: "CPF inválido. Verifique os números digitados."
                    });
                }
            } else if (rawIdentity.length === 14) {
                if (!isValidCNPJ(rawIdentity)) {
                    newErrors.push({
                        path: "identity",
                        msg: "CNPJ inválido. Verifique os números digitados."
                    });
                }
            } else {
                newErrors.push({
                    path: "identity",
                    msg: "O campo deve conter 11 dígitos para CPF ou 14 dígitos para CNPJ."
                });
            }

            if (newErrors.length > 0) {
                throw new Error("Erros no cadastro");
            }

            // Enviando o campo acceptTerms para o backend
            await Api.post("/users", {
                fullName: values.name.trim(),
                phone: values.phone.trim(),
                email: values.emailCadastro.trim(),
                password: values.passwordCadastro.trim(),
                identity: rawIdentity,
                acceptTerms: values.acceptTerms // <-- AQUI
            });

            // Caso deu tudo certo, já fazer o login e fechar modais
            await signIn({
                email: values.emailCadastro.trim(),
                password: values.passwordCadastro
            });

            setErrors(newErrors);
            setModalCadastro(false);
            setModalLogin(false);

            if (isMobile) {
                setShowMobileUploadNotice(true);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const apiErrorMessage = error.response.data.message;
                setErrors([{ path: "api", msg: apiErrorMessage }]);
            } else {
                setErrors((prevErrors) => [...prevErrors, ...newErrors]);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEsqueci = async () => {
        setLoading(true);
        let newErrors = [];
        try {
            if (!isValidEmail(valuesEsqueci.emailCadastro)) {
                newErrors.push({ path: "emailCadastro", msg: "E-mail inválido" });
            }

            if (newErrors.length > 0) {
                throw new Error("Erros no formulário");
            }

            await Api["post"]("/authenticate/forgot-password", {
                email: valuesEsqueci.emailCadastro,
            });
            setErrors(newErrors);
            setEsqueciSuccess(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setEsqueciSuccess(false);
            if (newErrors.length === 0) {
                newErrors.push({ path: "error", msg: "Não foi possível enviar sua solicitação, tente novamente." });
            }
            setErrors((prevErrors) => [...prevErrors, ...newErrors]);
        }
    };

    const handleReset = async () => {
        setLoading(true);
        let newErrors = [];
        try {
            if (!isValidEmail(valuesReset.emailCadastro)) {
                newErrors.push({ path: "emailCadastro", msg: "E-mail inválido" });
            }

            if (valuesReset.passwordCadastro.length < 8) {
                newErrors.push({ path: "passwordCadastro", msg: "A senha deve ter pelo menos 8 caracteres" });
            }

            if (newErrors.length > 0) {
                throw new Error("Erros no formulário");
            }

            await Api["post"]("/authenticate/reset-password", {
                token: token,
                email: valuesReset.emailCadastro,
                newPassword: valuesReset.passwordCadastro,
            });
            setErrors(newErrors);
            setResetSuccess(true);
            setModalLogin(false);
            setLoading(false);
            navigate("/");
        } catch (error) {
            setLoading(false);
            setResetSuccess(false);
            if (newErrors.length === 0) {
                newErrors.push({ path: "error", msg: "Não foi possível resetar sua senha, tente novamente." });
            }
            setErrors((prevErrors) => [...prevErrors, ...newErrors]);
        }
    };

    return (
        <>
            <Cart isOpen={cart} onClose={() => setCart(false)} />
            <Flex bg={isExpanded ? colors.primary : dark ? colors.black : "transparent"} color="white" justifyContent="space-between" alignItems="center" px={2} py={6} position="relative" {...rest}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100px',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0))',
                    zIndex: 0,
                    pointerEvents: 'none',
                    opacity: !isExpanded ? 1 : 0
                }} />
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundYellow})`,
                    zIndex: 0,
                    pointerEvents: 'none',
                    backgroundSize: 'cover',
                    opacity: isExpanded ? 0.15 : 0
                }} />
                <Container maxW="container.xl">
                    <Flex bg="transparent" color="white" justifyContent="space-between" alignItems="center" position="relative" {...rest}>
                        <Box>
                            <Image cursor={"pointer"} onClick={() => navigate("/")} src={require(isExpanded ? "../assets/icons/logoblack.png" : "../assets/icons/logo.png")} w={100} marginTop={-15} />
                        </Box>

                        <HStack spacing={4}>
                            <IconGhostButton onClick={() => navigate("/produtos")} icon={<Image w={'17.5px'} src={require(isExpanded ? "../assets/header/search_black.png" : "../assets/header/search.png")} />} bg="transparent" />

                            {!user && <>
                                <GhostButton display={{ base: 'none', lg: 'block' }} color={isExpanded ? colors.black : colors.white} text="Como funciona?" onClick={() => setHowItWorksVideo(true)} />
                                <GhostButton text="Entrar" color={isExpanded ? colors.black : colors.white} onClick={() => setModalLogin(true)} />
                                {isExpanded ?
                                    <BlackButton text="Vender conteúdo" onClick={() => setModalCadastro(true)} /> :
                                    <YellowButton text="Vender conteúdo" onClick={() => setModalCadastro(true)} />
                                }
                            </>}

                            {user && <>
                                {hasAdministratorRole && <GhostButton display={{ base: 'none', md: 'block' }} color={isExpanded ? colors.black : colors.white} text="Aprovações" onClick={() => navigate("/admin/lista")} />}
                                {hasAdministratorRole && <GhostButton display={{ base: 'none', md: 'block' }} color={isExpanded ? colors.black : colors.white} text="Saques" onClick={() => navigate("/admin/saques")} />}
                                {hasAdministratorRole && <GhostButton display={{ base: 'none', md: 'block' }} color={isExpanded ? colors.black : colors.white} text="L. Usuários" onClick={() => navigate("/admin/users/list")} />}
                                <GhostButton display={{ base: 'none', lg: 'block' }} color={isExpanded ? colors.black : colors.white} text="Como funciona?" onClick={() => setHowItWorksVideo(true)} />
                                {isExpanded ?
                                    <BlackButton display={{ base: 'none', md: 'block' }} text="Fazer Upload" onClick={toggleExpansion} /> :
                                    <YellowButton display={{ base: 'none', md: 'block' }} text="Fazer Upload" onClick={toggleExpansion} />
                                }
                                <IconGhostButton onClick={() => setCart(true)} icon={<Image w={'17.5px'} src={require(isExpanded ? "../assets/header/bag_black.png" : "../assets/header/bag.png")} />} borderWidth={1} borderColor={textColor} />
                                <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end" gutter={20}>
                                    <PopoverTrigger>
                                        <Button onClick={onOpen} variant="ghost" borderRadius={50} borderWidth={1} borderColor={textColor} rightIcon={<FaList color={textColor} />}>
                                            <Avatar name={user?.name} size="xs" backgroundColor={textColor} color={isExpanded ? colors.primary : colors.black} />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent bgColor={colors.grayBack} color={colors.white} borderRadius={20}>
                                        <PopoverBody px={5} py={10}>
                                            <VStack spacing={4} align="flex-start">
                                                {[
                                                    { text: "Ver conta detalhada", index: 0, img: 1, onClick: () => { navigate("/conta") } },
                                                    { text: "Minhas Informações", index: 1, img: 1, onClick: () => { setModalProfileOption(1); setModalProfile(true); } },
                                                    { text: "Meu Perfil", index: 2, img: 2, onClick: () => { setModalProfileOption(2); setModalProfile(true); } },
                                                    { text: "Endereço", index: 3, img: 3, onClick: () => { setModalProfileOption(3); setModalProfile(true); } },
                                                    { text: "Pagamentos", index: 4, img: 4, onClick: () => { setModalProfileOption(4); setModalProfile(true); } },
                                                    { text: "Preferências", index: 5, img: 5, onClick: () => { setModalProfileOption(5); setModalProfile(true); } },
                                                    { text: "Compras", index: 7, img: 4, onClick: () => { setModalProfileOption(7); setModalProfile(true); } },
                                                    ...(user.roles && Object.keys(user.roles).length > 1 ? [{ text: "Faturamento", index: 8, img: 4, onClick: () => { setModalProfileOption(8); setModalProfile(true); } }] : []),
                                                    { text: "Sair", index: 6, img: 6, onClick: () => signOut() },
                                                ].map(({ icon, text, img, onClick, index }) => (
                                                    <GhostButton
                                                        key={index}
                                                        icon={<Image src={require("../assets/user/" + (img) + ".png")} w={5} h={5} />}
                                                        color={colors.white}
                                                        text={text}
                                                        onClick={onClick}
                                                    />
                                                ))}
                                            </VStack>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </>}
                        </HStack>
                    </Flex>

                    {isExpanded && <>
                        <Flex py={20} justifyContent="space-around" alignItems="center">
                            <Box maxW={"md"}>
                                <SlideFade offsetY={20} in={isExpanded}>
                                    <Text fontSize="5xl" color={textColor} lineHeight={"50px"} fontWeight="bold" mb={2}>
                                        Monetize seu acervo de vídeos
                                    </Text>
                                    <Flex alignItems="center" mt={5}>
                                        <Image src={require("../assets/header/clock.png")} mr={2} />
                                        <Text fontSize="md" fontWeight="500" color={textColor} mr={4}>
                                            Em menos de 5min
                                        </Text>
                                        <Image src={require("../assets/header/inifity.png")} mr={2} />
                                        <Text fontSize="md" fontWeight="500" color={textColor}>
                                            Ganhos Ilimitados
                                        </Text>
                                        <IconButton
                                            variant='solid'
                                            colorScheme={colors.grayBack}
                                            backgroundColor={colors.grayBack}
                                            color={colors.white75}
                                            borderRadius='xl'
                                            size='md'
                                            onClick={() => setHowItWorks(true)}
                                            icon={<FaMoneyBillWave />}
                                            mr={2} />
                                        <Text
                                            fontSize="md"
                                            fontWeight="500"
                                            color={textColor}
                                            onClick={() => setHowItWorks(true)}
                                            cursor="pointer"
                                        >
                                            Quanto eu ganho?
                                        </Text>
                                    </Flex>
                                </SlideFade>
                            </Box>
                            <Center
                                p={"50px"}
                                borderRadius="lg"
                                borderWidth="1px"
                                borderColor={colors.grayBack}
                                borderStyle="dashed"
                            >
                                <SlideFade offsetY={20} in={isExpanded}>
                                    <VStack spacing={4}>
                                        {user ? <>
                                            <Text fontSize="sm" color={textColor}>
                                                Envie seu conteúdo
                                            </Text>
                                            <BlackButton text="Enviar video" onClick={() => { navigate("/upload"); setIsExpanded(false); }} />
                                        </> : <>
                                            <Text fontSize="sm" color={textColor}>
                                                Crie sua conta
                                            </Text>
                                            <BlackButton text="Cadastrar" onClick={() => { setModalCadastro(true); setModalLogin(false); }} />
                                        </>}
                                    </VStack>
                                </SlideFade>
                            </Center>
                        </Flex>
                    </>}
                </Container>
            </Flex >

            {/* MODAL LOGIN */}
            <Modal isOpen={modalLogin} onClose={() => setModalLogin(false)} isCentered>
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
                <ModalContent backgroundColor={colors.background} color={colors.white}>
                    <ModalCloseButton color="white" />
                    <ModalBody p={10}>
                        <Text mb={2} color={colors.white} align="center" fontSize="2xl">
                            Entre em sua conta
                        </Text>
                        <Text mb={10} color={colors.gray} align="center" fontSize="sm">
                            Veja seus vídeos, faça downloads e gerencie sua conta <Text as="span" color={colors.white}>HausTri</Text>
                        </Text>
                        {loading ? <Loading /> : <>
                            <CustomInput
                                placeholder="E-mail"
                                mb={3}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <CustomInput
                                placeholder="Senha"
                                mb={3}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        login();
                                    }
                                }}
                            />
                            {errors.find(x => x.path === "login") && (
                                <Text mb={5} color={colors.red} align="center" fontSize="sm">
                                    {errors.find(x => x.path === "login")?.msg}
                                </Text>
                            )}
                            <Flex justifyContent="center" alignItems="center" gap={5} mb={10}>
                                <GhostButton text="Esqueci a Senha" size="xs" borderRadius={5} onClick={() => { setEsqueciSuccess(false); setModalEsqueci(true); setModalLogin(false); }} />
                            </Flex>
                            <YellowButton text="Acessar Conta" w="full" size="lg" borderRadius={5} mb={5} onClick={login} />
                            <GhostButton text="Cadastrar" w="full" size="lg" borderRadius={5} onClick={() => { setModalCadastro(true); setModalLogin(false); }} />
                        </>}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* MODAL CADASTRO */}
            <Modal isOpen={modalCadastro} onClose={() => setModalCadastro(false)} isCentered>
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
                <ModalContent backgroundColor={colors.background} color={colors.white}>
                    <ModalCloseButton color="white" />
                    <ModalBody p={10}>
                        <Text mb={2} color='white' align="center" fontSize="2xl">Crie sua conta</Text>
                        <Text mb={10} color={colors.gray} align="center" fontSize="sm">
                            Veja seus vídeos, faça downloads e gerencie sua conta{" "}
                            <Text as="span" color={colors.white}>HausTri</Text>
                        </Text>

                        <CustomFormInput
                            id="name"
                            placeholder="Nome"
                            errors={errors}
                            values={values}
                            setValues={setValues}
                        />
                        <CustomFormInput
                            id="phone"
                            placeholder="Telefone"
                            errors={errors}
                            values={values}
                            setValues={setValues}
                        />
                        <CustomFormInput
                            id="emailCadastro"
                            placeholder="E-mail"
                            errors={errors}
                            values={values}
                            setValues={setValues}
                        />
                        <CustomFormInput
                            id="passwordCadastro"
                            placeholder="Senha"
                            type="password"
                            errors={errors}
                            values={values}
                            setValues={setValues}
                        />
                        <CustomFormInput
                            id="identity"
                            placeholder="CPF ou CNPJ"
                            errors={errors}
                            values={values}
                            setValues={setValues}
                            maxLength={18}
                            onChange={(e) => {
                                const rawValue = e.target.value.replace(/\D/g, '');
                                let maskedValue;

                                if (rawValue.length <= 11) {
                                    maskedValue = rawValue.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
                                } else {
                                    maskedValue = rawValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5');
                                }

                                setValues((prevValues) => ({
                                    ...prevValues,
                                    identity: maskedValue,
                                }));
                            }}
                        />
                        <Box mb={5} mt={5}>
                            <label style={{ cursor: "pointer" }}>
                                <input
                                    type="checkbox"
                                    checked={values.acceptTerms}
                                    onChange={(e) =>
                                        setValues((prev) => ({
                                            ...prev,
                                            acceptTerms: e.target.checked,
                                        }))
                                    }
                                    style={{ marginRight: "8px", transform: "scale(1.2)" }}
                                />
                                <span style={{ color: colors.white, fontSize: "0.9rem" }}>
                                    Eu aceito os{" "}
                                    <a
                                        href="https://haustristorage.blob.core.windows.net/haustricontainer/termoDeUsoHaustri.docx?sp=r&st=2024-12-15T14:08:20Z&se=2400-12-15T22:08:20Z&spr=https&sv=2022-11-02&sr=b&sig=uQ41Fsi0H7vr4jbnd1QqKOMy49PvthxKCDKEACNAmJU%3D"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            color: colors.white,
                                            textDecoration: "underline",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Termos de Uso
                                    </a>
                                </span>
                            </label>
                        </Box>

                        {errors.length > 0 && (
                            <Text mb={5} color={colors.red} align="center" fontSize="sm">
                                {errors.map((error) => (
                                    <span key={error.path}>
                                        {error.msg}
                                        <br />
                                    </span>
                                ))}
                            </Text>
                        )}

                        <YellowButton
                            isLoading={loading}
                            loadingText='Realizando cadastro...'
                            text="Cadastrar"
                            w="full"
                            size="lg"
                            borderRadius={5}
                            mb={5}
                            onClick={handleCadastro}
                        />
                        <GhostButton
                            text="Voltar para Login"
                            w="full"
                            size="lg"
                            borderRadius={5}
                            onClick={() => {
                                setModalCadastro(false);
                                setModalLogin(true);
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* MODAL ESQUECI */}
            <Modal isOpen={modalEsqueci} onClose={() => setModalEsqueci(false)} isCentered>
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
                <ModalContent backgroundColor={colors.background} color={colors.white}>
                    <ModalCloseButton color="white" />
                    <ModalBody p={10}>
                        <Text mb={2} color='white' align="center" fontSize="2xl">Esqueci minha senha</Text>
                        {loading ? <Loading /> : <>
                            {esqueciSuccess ? <>
                                <Text mb={10} color={colors.gray} align="center" fontSize="sm">
                                    Se o seu e-mail é válido, você receberá um e-mail contendo as instruções para redefinir a senha, siga os passos corretamente e acesse sua conta <Text as="span" color={colors.white}>HausTri</Text>
                                </Text>
                            </> : <>
                                <Text mb={10} color={colors.gray} align="center" fontSize="sm">
                                    Informe seu e-mail abaixo para receber a redefinição de senha da sua conta <Text as="span" color={colors.white}>HausTri</Text>
                                </Text>
                                <CustomFormInput id="emailCadastro" placeholder="E-mail" errors={errors} values={valuesEsqueci} setValues={setValuesEsqueci} />

                                {errors.find(x => x.path === "error") && <>
                                    <Text mb={5} color={colors.red} align="center" fontSize="sm">
                                        {errors.find(x => x.path === "error")?.msg}
                                    </Text>
                                </>}
                                <YellowButton text="Enviar" w="full" size="lg" borderRadius={5} mb={5} onClick={handleEsqueci} />
                                <GhostButton text="Voltar para Login" w="full" size="lg" borderRadius={5} onClick={() => { setModalEsqueci(false); setModalLogin(true); }} />
                            </>}
                        </>}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* MODAL RESET */}
            <Modal isOpen={modalReset} onClose={() => setModalReset(false)} isCentered>
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
                <ModalContent backgroundColor={colors.background} color={colors.white}>
                    <ModalCloseButton color="white" />
                    <ModalBody p={10}>
                        <Text mb={2} color='white' align="center" fontSize="2xl">Redefinir senha</Text>
                        {loading ? <Loading /> : <>
                            {resetSuccess ? <>
                                <Text mb={10} color={colors.gray} align="center" fontSize="sm">
                                    Sua senha foi redefinida com sucesso, faça login com sua conta <Text as="span" color={colors.white}>HausTri</Text>
                                </Text>
                            </> : <>
                                <Text mb={10} color={colors.gray} align="center" fontSize="sm">
                                    Para redefinir sua senha, informe seu e-mail e senha nova abaixo
                                </Text>
                                <CustomFormInput id="emailCadastro" placeholder="E-mail" errors={errors} values={valuesReset} setValues={setValuesReset} />
                                <CustomFormInput id="passwordCadastro" placeholder="Senha" type="password" errors={errors} values={valuesReset} setValues={setValuesReset} />

                                {errors.find(x => x.path === "error") && <>
                                    <Text mb={5} color={colors.red} align="center" fontSize="sm">
                                        {errors.find(x => x.path === "error")?.msg}
                                    </Text>
                                </>}

                                <YellowButton text="Redefinir" w="full" size="lg" borderRadius={5} mb={5} onClick={handleReset} />
                            </>}
                            <GhostButton text="Voltar para Login" w="full" size="lg" borderRadius={5} onClick={() => { setModalReset(false); setModalLogin(true); }} />
                        </>}
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* MODAL PROFILE */}
            {isMobile ? (
                <Modal
                    isOpen={modalProfile}
                    onClose={() => setModalProfile(false)}
                    size="6xl"
                >
                    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
                    <ModalContent
                        backgroundColor={colors.background}
                        color={colors.white}
                        minWidth="768px"
                        overflowX="auto"
                        position="absolute"
                        top="0"
                        left="0"
                        transform="none"
                    >
                        <ModalCloseButton color="white" />
                        <ModalBody minWidth="768px" overflowX="auto">
                            <Profile
                                optionInitial={modalProfileOption}
                                onClose={() => setModalProfile(false)}
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            ) : (
                <Modal
                    isOpen={modalProfile}
                    onClose={() => setModalProfile(false)}
                    size="6xl"
                    isCentered
                >
                    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
                    <ModalContent backgroundColor={colors.background} color={colors.white}>
                        <ModalCloseButton color="white" />
                        <ModalBody>
                            <Profile
                                optionInitial={modalProfileOption}
                                onClose={() => setModalProfile(false)}
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}

            <HowItWorks isOpen={howItWorks} onClose={() => setHowItWorks(false)} />
            <HowItWorksVideo isOpen={howItWorksVideo} onClose={() => setHowItWorksVideo(false)} videoUrl={'https://www.youtube.com/watch?v=-JE-TkSy-I8'} />

            {/* NOTICES MODAL */}
            <NoticesModal
                isOpen={showMobileUploadNotice}
                onClose={() => setShowMobileUploadNotice(false)}
                title="Seja bem-vindo!"
                text="Atualmente, os uploads só podem ser feitos pelo computador (desktop). Em breve liberaremos essa funcionalidade via celular."
                isEnableTimer={true}
                timerSeconds={5}
            />
        </>
    );
};

export default Header;