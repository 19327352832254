import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, Button, Container, Flex, InputGroup, InputRightElement, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GhostButton } from '../../components/button';
import { ButtonGroupCustom } from '../../components/buttonGroup';
import { Footer } from '../../components/footer';
import Header from '../../components/header';
import HorizontalScrollingText from '../../components/horizontalScrollingText';
import { CustomInput } from '../../components/input';
import { Loading } from '../../components/loading';
import { VideoSliderHome } from '../../components/slider';
import { VideoPlayerHeader } from '../../components/video';
import { Videos } from '../../components/videos';
import { useAuth } from '../../hooks/auth';
import Api from '../../services/api';
import { colors } from '../../styles/colors';

const Home = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const inputRef = useRef(null);

  const placeholderText = useBreakpointValue({
    base: 'ex. praia no RJ',
    md: 'ex. palmeiras na praia no rio de janeiro',
  });

  const [texto, setTexto] = useState("");
  const [videosHighlight, setVideosHighlight] = useState([]);
  const [loadingVideos, setLoadingVideos] = useState(true);
  const [videos, setVideos] = useState([]);

  const [allCategories, setAllCategories] = useState(null);
  const [categories, setCategories] = useState(null);

  const dataLoadedRef = useRef(false);

  const [activeCategorie, setActiveCategorie] = useState(-1);
  const [page, setPage] = useState(1);
  const [loadedCount, setLoadedCount] = useState(0);
  const [maxReached, setMaxReached] = useState(false);

  const maxVideos = 21;

  useEffect(() => {
    // Se não tiver 'clearcache' na URL, força reload com param.
    if (!window.location.search.includes('clearcache')) {
      const sep = window.location.search ? '&' : '?';
      // Troca a URL e recarrega (como se fosse Ctrl+F5)
      window.location.replace(window.location.href + sep + 'clearcache=' + new Date().getTime());
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, []);

  // Carrega Destaques e Categorias apenas uma vez
  async function loadAll() {
    try {
      const [videohighlightsRes, categoriesRes] = await Promise.all([
        Api.get('/video/highlights'),
        Api.get('/categories')
      ]);

      const videohighlights = Object.values(videohighlightsRes.data).map(x => ({
        id: x.IdVideo,
        videoUrl: x.VideoLinkCompressedAndWatermark,
        thumbnailUrl: x.ThumbMomentImgLink,
        text: x.HighlightTitle,
        description: x.HighlightSubTitle,
        onClick: () => navigate("/produto/" + x.IdVideo)
      }));
      setVideosHighlight(videohighlights);

      const categoriesArray = Object.values(categoriesRes.data);
      setAllCategories(categoriesArray);

      const shuffledCategories = shuffleArray(categoriesArray);
      const selectedCategories = shuffledCategories.slice(0, 9);
      setCategories(selectedCategories);

      // Carrega pela primeira vez, categoria = -1 (Todos)
      resetVideosAndLoad(-1);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

  // Reseta dados e carrega página 1 da categoria
  const resetVideosAndLoad = useCallback(async (categoryId) => {
    setActiveCategorie(categoryId);
    setPage(1);
    setLoadedCount(0);
    setMaxReached(false);
    setVideos([]);
    await getVideos(categoryId, 1, false);
    // eslint-disable-next-line
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Ajuste principal: enviamos `excludeVideosId` para evitar repetição.
  async function getVideos(categoryId, pageParam, loadMore) {
    // Se já atingimos o máximo ou o estado maxReached, abortamos
    if (maxReached || loadedCount >= maxVideos) return;

    setLoadingVideos(true);

    // Se for uma nova página, vamos excluir os IDs já exibidos
    const excludeVideosId = loadMore ? videos.map((v) => v.id) : [];

    const limitBase = 6;
    let limitNow = limitBase;
    if (loadedCount + limitBase > maxVideos) {
      limitNow = maxVideos - loadedCount;
    }

    try {
      const response = await Api.post('/video/filters', {
        idUser: user?.id ? user.id : 0,
        page: pageParam,
        limitPerPage: limitNow,
        categoriesId: categoryId === -1 ? [] : [categoryId],
        randomOrder: true,
        excludeVideosId // <--- Aqui o param que enviamos
      });

      const newVideos = response.data.videos.map(x => ({
        id: x.idVideo,
        title: x.titleScene,
        description: x.descriptionScene,
        price: x.price,
        isNews: x.isNews,
        commissionType: x.idCommissionValueType,
        thumbnailUrl: x.thumbMomentImgLink,
        videoUrl: x.videoLinkCompressedAndWatermark,
        city: x.city,
        local: x.local,
        state: x.state,
        displayName: x.displayName,
        fullName: x.fullName,
        resolution: x.resolution,
        duration: x.duration,
        isFavorite: x.isFavorite,
        totalDownloads: x.totalDownloads,
        categories: x.categories.map(cat => ({
          id: cat.idCategories,
          description: cat.description
        })),
        tags: x.tags.map(tag => ({
          id: tag.IdTags,
          description: tag.Description
        })),
        onClick: () => navigate("/produto/" + x.idVideo)
      }));

      if (loadMore) {
        setVideos(prev => [...prev, ...newVideos]);
      } else {
        setVideos(newVideos);
      }

      // Aqui faz a atualização de loadedCount e a checagem para setar maxReached
      setLoadedCount(prev => {
        const updatedCount = prev + newVideos.length;
        if (newVideos.length < limitNow || updatedCount >= maxVideos) {
          setMaxReached(true);
        }
        return updatedCount;
      });
    } catch (error) {
      console.error('Error loading videos:', error);
    } finally {
      setLoadingVideos(false);
    }
  }

  useEffect(() => {
    if (!dataLoadedRef.current) {
      dataLoadedRef.current = true;
      loadAll();
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeCategory = (categoryId) => {
    resetVideosAndLoad(categoryId);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate("/produtos", { state: { text: texto } });
    }
  };

  // Scroll infinito para paginação
  useEffect(() => {
    const handleScroll = () => {
      if (maxReached || loadingVideos) return;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
        setPage(oldPage => {
          const nextPage = oldPage + 1;
          getVideos(activeCategorie, nextPage, true);
          return nextPage;
        });
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [maxReached, loadingVideos, activeCategorie]);

  const fixedVideo = {
    videoUrl: process.env.REACT_APP_FIXED_VIDEO_URL || "https://haustristorage.blob.core.windows.net/haustricontainer/bannerVT01.mp4?sp=r&st=2024-07-27T14:22:53Z&se=2200-07-27T22:22:53Z&sv=2022-11-02&sr=b&sig=LTQOKwf98JK5Z%2Fj6%2B6QsJGb2cSZcWl%2BXbTLDLXknDFA%3D",
    text: [
      "HAUSTRI é daqui!",
      "Banco de Vídeos",
      "Genuinamente Brasileiro"
    ],
    description: "Vídeo Promocional",
    creator: "Home Produtora",
    createdIn: "Santa Cruz do Sul"
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header token={token} />
        <div style={{
          height: '80vh',
          paddingBottom: 200,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Container
            maxW="container.xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height='full'
          >
            <VStack align="stretch" maxW={"2xl"} spacing={10}>
              <Box textAlign="center" color={colors.white} lineHeight="50px">
                {fixedVideo.text.map((line, index) => (
                  <Text key={index} fontSize={{ base: '4xl', md: '5xl' }} fontWeight="semibold">
                    {line}
                  </Text>
                ))}
              </Box>
              <InputGroup size='lg' backgroundColor={"transparent"} mt={18}>
                <CustomInput
                  backgroundColor={"transparent"}
                  onChange={(e) => setTexto(e.target.value)}
                  onKeyPress={handleKeyPress}
                  borderRadius={10}
                  placeholder={placeholderText}
                  ref={inputRef}
                />
                <InputRightElement>
                  <InputRightElement width='160px'>
                    <Button
                      backgroundColor={colors.whiteTransparent}
                      leftIcon={<FaSearch />}
                      fontSize="md"
                      size='lg'
                      onClick={() => navigate("/produtos", { state: { text: texto } })}
                    >
                      Buscar Vídeos
                    </Button>
                  </InputRightElement>
                </InputRightElement>
              </InputGroup>
            </VStack>
          </Container>

          <VideoPlayerHeader
            key={fixedVideo.id}
            videoUrl={fixedVideo.videoUrl}
            fadeState={"fade-in"}
          />
        </div>
      </div>

      <div style={{ marginTop: -300, height: 300, backgroundImage: `linear-gradient(to bottom, transparent, ${colors.black})` }}>
      </div>

      <Container maxW="container.xl" mt={-20} mb={20}>
        <Text fontSize="lg" fontWeight="600">DESTAQUES HAUS</Text>
        <Text fontSize="sm" fontWeight="200">Conteúdo produzido pelos melhores criadores</Text>

        <Box mt={10} pb={50} borderBottomWidth={1} borderBottomColor={colors.whiteTransparent}>
          <VideoSliderHome videos={videosHighlight} />
        </Box>

        <Box mt={50}>
          <Flex
            justifyContent={{ base: 'flex-start', lg: 'center' }}
            overflowX="auto"
            width="100%"
            px={4}
          >
            <ButtonGroupCustom
              active={activeCategorie}
              buttons={
                categories && categories.length > 0
                  ? [
                    {
                      id: -1,
                      text: "Todos",
                      onClick: () => handleChangeCategory(-1)
                    },
                    ...categories
                      .filter((x) => x.isActive)
                      .map((x) => ({
                        id: x.idCategories,
                        text: x.description,
                        onClick: () => handleChangeCategory(x.idCategories),
                      })),
                  ]
                  : []
              }
            />
          </Flex>
        </Box>

        <Box mt={30}>
          {loadingVideos && videos.length === 0
            ? <Loading />
            : <Videos videos={videos} setVideos={setVideos} />
          }
        </Box>

        {maxReached && (
          <Box mt={70} justify="center" align="center">
            <GhostButton
              text={"Carregar mais conteúdo"}
              onClick={() => navigate("/produtos")}
              p={7}
              borderRadius={50}
              borderWidth={1}
              borderColor={colors.white75}
            />
          </Box>
        )}
      </Container>

      <Box justify="center" align="center" onClick={() => navigate("/produtos")} style={{ cursor: 'pointer' }}>
        {allCategories && (
          <>
            <HorizontalScrollingText
              texts={[...allCategories, ...allCategories]}
              scrollDirection={"rightToLeft"}
            />
            <HorizontalScrollingText
              texts={[...allCategories, ...allCategories]}
              scrollDirection={"leftToRight"}
            />
          </>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default Home;